import { graphql } from 'gatsby'
import React from 'react'
import ArticleEmphasis from 'src/components/article-emphasis/article-emphasis'
import ArticleList from 'src/components/article-list/article-list'
import Button from 'src/components/button/button'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'

const IndexPage = ({ data }) => {
  const { posts, content } = data // data.markdownRemark holds your post data
  const [{ node: emphasisArticle }] = [...posts.edges].splice(0, 1)
  const articles = [...posts.edges].splice(1, posts.edges.length)

  return (
    <Layout>
      <SEO title="Accueil" />
      <div className="home">
        <div className="home__emphasis">
          <p className="home__title">Dernier article</p>
          <ArticleEmphasis
            withLink={true}
            article={emphasisArticle}
            withMeta={false}
          />
          <Button url={emphasisArticle.frontmatter.path}>Lire la suite</Button>
        </div>

        <div className="home__about content">
          <p className="home__title">À propos</p>
          <div dangerouslySetInnerHTML={{ __html: content.html }}></div>
          <br />
          <Button url="/contact/">Me contacter</Button>
        </div>

        <div className="home__articles">
          <ArticleList articles={articles} />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: {
        fileAbsolutePath: { glob: "**/content/posts/*.md" }
        frontmatter: { draft: { eq: false } }
      }
    ) {
      edges {
        node {
          timeToRead
          id
          frontmatter {
            path
            title
            categories
            description
            picture
            date
          }
        }
      }
    }
    content: markdownRemark(frontmatter: { path: { eq: "//" } }) {
      id
      frontmatter {
        title
      }
      html
    }
  }
`
