import { Link } from 'gatsby'
import React from 'react'
import style from './button.module.scss'

const Button = ({ url, children, type }) => {
  const innerButton = <span className={style.buttontext}>{children}</span>

  return url ? (
    <Link className={`button ${style.button}`} to={url}>
      {innerButton}
    </Link>
  ) : (
    <button className={style.button} type={type}>
      {innerButton}
    </button>
  )
}

Button.defaultProps = {
  type: 'button',
}

export default Button
