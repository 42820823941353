import React from 'react'
import { Clock } from 'react-feather'
import ArticleDate from 'src/components/article-date/article-date'
import Badge from 'src/components/badge/badge'
import style from './article-list-item.module.scss'

const ArticleListItem = ({ article }) => {
  const regex = new RegExp(/\/upload(\/[\w,]+)?\/v[0-9]+/, 'i')

  function transformImagePath(path) {
    const matchRegex = regex.exec(path)
    const imageConf = '/c_fill,h_175,w_340'
    return path.replace(`upload${matchRegex[1] || ''}`, `upload${imageConf}`)
  }

  return (
    <>
      <div className={style.article__img}>
        <div className={style.article__imginner}>
          <Badge className={style.article__duration}>
            <Clock size={10} />
            &nbsp;&nbsp;
            {article.timeToRead} min
          </Badge>

          <img
            alt={article.frontmatter.title}
            src={transformImagePath(article.frontmatter.picture)}
            className="u-shadow u-img"
            loading="lazy"
          />
        </div>
      </div>

      <div className={style.article__text}>
        <ArticleDate
          withMeta={false}
          className={style.article__date}
          date={article.frontmatter.date}
          categories={article.frontmatter.categories}
        />
        <h2 className={style.article__title}>{article.frontmatter.title}</h2>
        <p className={style.article__description}>
          {article.frontmatter.description}
        </p>
      </div>
    </>
  )
}

export default ArticleListItem
