import { Link } from 'gatsby'
import React from 'react'
import ArticleListItem from 'src/components/article-list-item/article-list-item'
import style from './article-list.module.scss'

const ArticleList = ({ articles }) => {
  return (
    <ul className={style.ul}>
      {articles.map(({ node }) => (
        <li className={style.article} key={node.id}>
          <Link className={style.article__link} to={node.frontmatter.path}>
            <ArticleListItem article={node} />
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default ArticleList
